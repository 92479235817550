<template>
	<div class="row">
		<div class="col-12" v-show="loadTrigger">
			<LoadingSpinner v-show="loadTrigger" class="col-12"/>
		</div>
		<div class="col-md-8" v-show="!loadTrigger">
			<h2>{{ $t('trigger.declencheur') }}</h2>
			<div class="box row">
				<b-form-group class="col-xl-6 col-md-12">
					<e-select
						v-model="form.triggerentity_triggerentitytype"
						id="triggerentity_triggerentitytype"
						track-by="name"
						label="name"
						:placeholder="labelTitleTrigger"
						:deselectLabel="deselectLabel"
						:selectedLabel="selectedLabel"
						:selectLabel="enter_to_select"
						:options="triggers_entity_type"
						:searchable="true"
						:show-labels="false"
						:allow-empty="false"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</b-form-group>

				<b-form-group class="mb-0 col-xl-6 col-md-12" v-if="need_acte">
					<e-select
						v-model="form.triggerentity_actestype"
						id="triggerentity_actestype"
						track-by="name"
						label="name"
						:placeholder="labelTitleActeType"
						:deselectLabel="deselectLabel"
						:selectedLabel="selectedLabel"
						:selectLabel="enter_to_select"
						:options="actes_type"
						:loading="isLoadingTriggerActeType"
						:searchable="true"
						:show-labels="false"
						:allow-empty="false"
						group-values="actes" 
						group-label="groupe" 
						:group-select="false"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</b-form-group>
			</div>
			<h2 v-if="need_question">{{ $t("trigger.condition_declenchement") }}</h2>
			<div v-if="need_question" class="box">
				<template v-for="i in nb_triggers">
					<b-form-group :key="`cond-declenchement-${i}`">
						<label class="d-flex" v-if="form.questions[i - 1] != undefined">{{ form.questions[i - 1].name }} ? <font-awesome-icon @click="deleteQuestion(i - 1)" class="ml-auto mr-2" :icon="['fal', 'times']" /></label>
						<e-select
							v-if="form.questions[i - 1] == undefined"
							v-model="form.questions[i - 1]"
							:id="'question_' + i"
							track-by="name"
							label="name"
							:placeholder="labelTitleQuestion"
							:deselectLabel="deselectLabel"
							:selectedLabel="selectedLabel"
							:selectLabel="enter_to_select"
							:options="questions"
							:loading="isLoadingQuestions"
							:searchable="true"
							:show-labels="false"
							:allow-empty="true"
						>
							<template slot="option" slot-scope="{ option }">{{ findTranslation(option.name, 'questions.') }}</template>
							<template slot="singleLabel" slot-scope="{ option }">{{ findTranslation(option.name, 'questions.') }}</template>
							<template slot="noOptions">{{ $t('global.list_empty') }}</template>
						</e-select>
						<e-select
							v-else
							v-model="form.responses[i - 1]"
							:id="'reponse_' + i"
							track-by="name"
							label="name"
							:placeholder="labelTitleReponse"
							:deselectLabel="deselectLabel"
							:selectedLabel="selectedLabel"
							:selectLabel="enter_to_select"
							:options="form.questions[i - 1].responses"
							:searchable="true"
							:show-labels="false"
							:allow-empty="true"
							:multiple="true"
							:class="{ 'is-invalid': errors && errors.indexOf('responses_' + (i - 1)) > -1 }"
						>
							<template slot="option" slot-scope="{ option }">{{ findTranslation(option.name, 'reponses.') }}</template>
							<template slot="singleLabel" slot-scope="{ option }">{{ findTranslation(option.name, 'reponses.') }}</template>
							<template slot="tag" slot-scope="{ option }">
								<div class="multiselect__tag">
									<span>{{ findTranslation(option.name, 'reponses.') }}</span>
									<i class="multiselect__tag-icon" @mousedown.prevent="removeTag(option, i - 1)" />
								</div>
							</template>
							<template slot="noOptions">{{ $t('global.list_empty') }}</template>
						</e-select>
					</b-form-group>
				</template>

				<div class="text-center" v-if="questions.length > nb_triggers && nb_triggers > 0">
					<b-button variant="secondary" @click.prevent="add_question"><font-awesome-icon :icon="['far', 'plus']" /> {{ $t('qualification.add_question') }}</b-button>
				</div>
			</div>
			<h2>{{ $t("trigger.declenche") }}</h2>
			<div class="box">
				<b-form-group>
					<e-select
						v-model="form.triggeredentity_triggeredentitytype"
						id="triggered_triggeredentitytype"
						track-by="name"
						label="name"
						:placeholder="labelTitleTriggered"
						:deselectLabel="deselectLabel"
						:selectedLabel="selectedLabel"
						:selectLabel="enter_to_select"
						:options="triggered_entity_type"
						:searchable="true"
						:show-labels="false"
						:allow-empty="false"
						:class="{ 'is-invalid': errors && errors.indexOf('triggeredentity_triggeredentitytype') > -1 }"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</b-form-group>

				<b-form-group v-if="need_acte_triggered">
					<e-select
						v-model="form.triggered_actestype"
						id="triggered_actestype"
						track-by="name"
						label="name"
						:placeholder="labelTitleActeType"
						:deselectLabel="deselectLabel"
						:selectedLabel="selectedLabel"
						:selectLabel="enter_to_select"
						:options="triggered_actestype"
						:loading="isLoadingTriggeredActeType"
						:searchable="true"
						:show-labels="false"
						:allow-empty="false"
						:class="{ 'is-invalid': errors && errors.indexOf('triggered_actestype') > -1 }"
						group-values="actes" 
						group-label="groupe" 
						:group-select="false"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</b-form-group>

				<b-form-group v-if="need_categorie">
					<e-select
						id="horse_cats"
						v-model="form.triggered_categorie"
						track-by="categorie_id"
						label="categorie_libelle"
						:placeholder="labelTitleCategorie"
						:options="horse_categories"
						:searchable="true"
						:allow-empty="false"
						:loading="isLoadingHorseCategories"
						:class="{ 'is-invalid': errors && errors.indexOf('triggered_categorie') > -1 }"
						:show-labels="false"
					>
						<template slot="noOptions">{{ $t('categorie.aucune_categorie') }}</template>
					</e-select>
				</b-form-group>

				<!-- Deplacer la classe active sur l'input number au focus sur l'input text et inversement -->
				<template v-if="need_period">
					<div class="timeplanned row mb-2 mt-2" v-for="i in nb_periods" :key="`timeplanned- ${i}`" >
						<div class="col-11">
							<div class="row no-gutters special-input">
								<div class="col-3">
									<b-input :ref="'label_' + (i - 1)" v-model="label[i - 1]" type="text" class="active" :placeholder="$t('trigger.nom')" :class="{ 'is-invalid': errors && errors.indexOf('triggered_label') > -1 && i == 1}"></b-input>
								</div>
								<div class="col-3">
									<b-input min="0" :ref="'debutOn_' + (i - 1)" v-model="periods[i - 1]" type="number" @focusout="makeFocusOn('debutOut_' + (i - 1), i)"></b-input>
									<b-input min="0" :ref="'debutOut_' + (i - 1)" :value="getPeriodLabel(periods[i - 1], 'debut')" type="text" @click="makeFocusOn('debutOn_' + (i - 1), i)" readonly class="active" :class="{ 'is-invalid': errors && errors.indexOf('triggered_periods') > -1 && i == 1}"></b-input>
								</div>
								<div class="col-3">
									<b-input min="0" :ref="'pasOn_' + (i - 1)" v-model="pas[i - 1]" type="number" @focusout="makeFocusOn('pasOut_' + (i - 1), i)"></b-input>
									<b-input min="0" :ref="'pasOut_' + (i - 1)" :value="getPeriodLabel(pas[i - 1], 'pas')" type="text" @click="makeFocusOn('pasOn_' + (i - 1), i)" readonly class="active"></b-input>
								</div>
								<div class="col-3">
									<b-input min="0" :ref="'dureeOn_' + (i - 1)" v-model="duree[i - 1]" type="number" @focusout="makeFocusOn('dureeOut_' + (i - 1))"></b-input>
									<b-input min="0" :ref="'dureeOut_' + (i - 1)" :value="getPeriodLabel(duree[i - 1], 'duree')" type="text" @click="makeFocusOn('dureeOn_' + (i - 1), i)" readonly class="active"></b-input>
								</div>
							</div>
							<div class="row no-gutters">
								<div class="col-5">
									<e-select
										v-model="billable[i - 1]"
										id="triggeredentity_billable"
										track-by="name"
										label="name"
										:placeholder="labelTitleFacturation"
										:deselectLabel="deselectLabel"
										:selectedLabel="selectedLabel"
										:selectLabel="enter_to_select"
										:options="facturation"
										:searchable="true"
										:show-labels="false"
										:allow-empty="true"
									>
										<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
										<template slot="noOptions">{{ $t('global.list_empty') }}</template>
									</e-select>
								</div>
								<b-form-group class="col-7">
									<e-select
										v-model="actestype[i - 1]"
										:id="'triggered_actestype-' + i"
										track-by="name"
										label="name"
										:placeholder="labelTitleActeType"
										:deselectLabel="deselectLabel"
										:selectedLabel="selectedLabel"
										:selectLabel="enter_to_select"
										:options="triggered_actestype"
										:loading="isLoadingTriggeredActeType"
										:searchable="true"
										:show-labels="false"
										:allow-empty="false"
										group-values="actes" 
										group-label="groupe" 
										:group-select="false"
									>
										<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
										<template slot="noOptions">{{ $t('global.list_empty') }}</template>
									</e-select>
								</b-form-group>
							</div>
						</div>
						<div class="col-1 acte_delete align-self-center">
							<button @click="deletePeriod(i - 1)" class="btn btn-secondary"><font-awesome-icon :icon="['fal', 'trash-alt']" /></button>	
						</div>
					</div>
				</template>
			</div>
			<h2 v-if="admin">{{ $t('trigger.admin') }}</h2>
			<div v-if="admin" class="box">
				<b-form-checkbox v-model="global">
					{{ $t('trigger.global') }}
				</b-form-checkbox>
			</div>
			<div class="text-center">
				<button class="btn btn-primary" @click.prevent="checkForm"><font-awesome-icon v-if="processing_form" :icon="['fas', 'spinner']" pulse />{{ $t('global.enregistrer') }}</button>
			</div>
		</div>
		<div class="col-md-4" v-show="!loadTrigger">
			<div class="box mt-5 sticky-top">
				<h3>{{ $t('trigger.resume') }}</h3>
				<h5>{{ $t('trigger.declencheur') }}</h5>
				<template v-if="form.triggerentity_triggerentitytype">
					&bull; {{ form.triggerentity_triggerentitytype.name }}
					<template v-if="form.triggerentity_triggerentitytype.type === 'acte' && form.triggerentity_actestype">
						 : {{ form.triggerentity_actestype.name }}
					</template>
				</template>
				<hr>
				<h5>{{ $t('trigger.condition_declenchement') }}</h5>
				<ul v-if="nb_triggers > 0 && form.questions[0]">
					<li v-for="i in nb_triggers" :key="i">
						<template v-if="form.questions[i - 1] != undefined">
							{{ form.questions[i - 1].name }} : 
							<template v-for="response in form.responses[i - 1]">
								{{ response.name }}  
							</template>
						</template>
					</li>
				</ul>
				<hr>
				<h5>{{ $t('trigger.declenche') }}</h5>
				<template v-if="form.triggeredentity_triggeredentitytype">
					&bull; {{ form.triggeredentity_triggeredentitytype.name }}
					<template v-if="form.triggeredentity_triggeredentitytype.type === 'acte' && form.triggered_actestype">
						 : {{ form.triggered_actestype.name }}
					</template>
					<template v-else-if="form.triggeredentity_triggeredentitytype.type === 'add_categorie_perso' && form.triggered_categorie">
						 : {{ form.triggered_categorie.categorie_libelle }}
					</template>
				</template>
				<br/>
				<template v-for="i in billable.length">
					&bull; {{ $t('trigger.periode_numero') }}{{ i }}
					<ul :key="i">
						<li>{{ $t('trigger.nom') }} : {{ label[i - 1] }}</li>
						<li>{{ $t('trigger.debut') }} : {{ getPeriodLabel(periods[i - 1], 'debut') }}</li>
						<li>{{ $t('trigger.pas') }} : {{ getPeriodLabel(pas[i - 1], 'pas') }}</li>
						<li>{{ $t('trigger.duree') }} : {{ getPeriodLabel(duree[i - 1], 'duree') }}</li>
						<li>{{ $t('trigger.facturation') }} : {{ billable[i - 1].name }}</li>
						<li>{{ $t('trigger.calcul_a_partir') }}
							<template v-if="actestype[i - 1]">
								{{ $t('trigger.dernier_acte') }} {{ actestype[i - 1].name }}
							</template>
							<template v-else>
								{{ $t('trigger.acte_declencheur')}}
							</template>
						</li>
					</ul>
				</template>
				<template v-if="global">
					<hr>
					<h5>{{ $t('trigger.global') }}</h5>
				</template>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import TableAction from "@/mixins/TableAction.js"
	import Config from "@/mixins/Config.js"
	import { EventBus } from 'EventBus'
	import Triggers from "@/mixins/Triggers.js"
	import Actes from "@/mixins/Actes.js"
	import User from "@/mixins/User.js"

	export default {
		name: "list",
		mixins: [Config, TableAction, Triggers, Actes, User],
		props: [],
		data () {
			return {
				labelTitleReponse: this.getTrad("trigger.search_trigger_reponse"),
				labelTitleQuestion: this.getTrad("trigger.search_trigger_question"),
				labelTitleTrigger: this.getTrad("trigger.search_trigger"),
				labelTitleTriggered: this.getTrad("trigger.search_triggered_type"),
				labelTitleActeType: this.getTrad("trigger.search_actes_type"),
				labelTitleFacturation: this.getTrad("trigger.search_facturation"),
				deselectLabel: this.getTrad("global.press_enter_to_remove"),
				selectedLabel: this.getTrad("global.selected_label"),
				enter_to_select: this.getTrad("global.press_enter_to_select"),
				labelTitleCategorie: this.getTrad("trigger.search_categorie"),
				triggers_entity_type: [],
				triggered_entity_type: [],
				errors: [],
				actes_type: [],
				triggered_actestype: [],
				triggers: [],
				responses: [],
				questions: [],
				save_questions: [],
				nb_periods: 1,
				nb_triggers: 1,
				label: [],
				label_fr: [],
				label_en: [],
				periods: [],
				pas: [],
				duree: [],
				billable: [],
				actestype: [],
				processing_form: false,
				isLoadingTriggerActeType: false,
				isLoadingQuestions: false,
				loadTrigger: false,
				facturation: [
					{
						id: 0,
						name: this.getTrad("trigger.facturer_aucun")
					},
					{
						id: 1,
						name: this.getTrad("trigger.tout_facturer")
					},
					{
						id: 2,
						name: this.getTrad("trigger.facturer_un_seul")
					}
				],
				form: {
					triggerentity_actestype: [],
					entity_type_id: null,
					triggerentity_triggerentitytype: null,
					triggeredentity_triggeredentitytype: null,
					triggered_actestype: [],
					questions: [],
					responses: [],
				},
				default_form: {
					entity_type_id: null,
					actes_type: null,
					triggerentity_triggerentitytype: null,
					triggeredentity_triggeredentitytype: null,
					triggered_actestype: null,
					questions: [],
					responses: [],
				},
				admin: false,
				global: false,
				need_question: false,
				need_acte: false,
				need_acte_triggered: false,
				need_period: false,
				need_categorie: false,
				horse_categories: [],
				isLoadingTriggeredActeType: false,
				isLoadingHorseCategories: false,
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				let user = await this.getUserAccessRights()
				if(user.indexOf("ADMN") > -1) {
                    this.admin = true
                }
				this.loadtriggers()
			},

			async loadtriggers() {
				this.loadTrigger = true
				let triggers_entity_type = await this.loadTriggerEntityType()

				for (let i = 0; i < triggers_entity_type.length; i++) {
					if(i == 0) {
						this.default_form.triggerentity_triggerentitytype = {
							id: triggers_entity_type[i].triggerentitytype_id,
							name: this.getTrad("trigger.entity_type."+triggers_entity_type[i].triggerentitytype_label),
							type: triggers_entity_type[i].triggerentitytype_label
						}
					}

					this.triggers_entity_type.push({
						id: triggers_entity_type[i].triggerentitytype_id,
						name: this.getTrad("trigger.entity_type."+triggers_entity_type[i].triggerentitytype_label),
						type: triggers_entity_type[i].triggerentitytype_label
					})
				}

				let triggered_entity_type = await this.loadTriggeredEntityType(true)

				for (let i = 0; i < triggered_entity_type.length; i++) {
					if(i == 0) {
						this.default_form.triggerentity_triggerentitytype = {
							id: triggered_entity_type[i].triggeredentitytype_id,
							name: this.getTrad("trigger.triggered_entity_type."+triggered_entity_type[i].triggeredentitytype_label),
							type: triggered_entity_type[i].triggeredentitytype_label
						}
					}

					this.triggered_entity_type.push({
						id: triggered_entity_type[i].triggeredentitytype_id,
						name: this.getTrad("trigger.triggered_entity_type."+triggered_entity_type[i].triggeredentitytype_label),
						type: triggered_entity_type[i].triggeredentitytype_label
					})
				}

				if(this.$route.params.triggerentity_id != undefined) {
					let trigger_entity = await this.getTriggerEntity(this.$route.params.triggerentity_id)

					this.global = trigger_entity.global

					if(this.global && !this.admin) {
						this.$router.push({name: 'triggersList'})
						this.failureToast('trigger.modif_trigger_global');
					}

					if(trigger_entity.trigger_entity_type.triggerentitytype_label === 'acte'){
						this.form.triggerentity_actestype = {
							id: trigger_entity.triggerentity_actestype,
							name: trigger_entity.actes_type.actestype_label,
						}
						this.need_question = true;
						this.need_acte = true;
					}

					this.form.triggerentity_triggerentitytype = {
						id: trigger_entity.trigger_entity_type.triggerentitytype_id,
						type: trigger_entity.trigger_entity_type.triggerentitytype_label,
						name: this.getTrad("trigger.entity_type."+trigger_entity.trigger_entity_type.triggerentitytype_label)
					}
					this.form.entity_type_id = trigger_entity.triggerentity_id

					this.triggers = await this.loadTriggers(this.$route.params.triggerentity_id)

					let responses = [];

					if(this.triggers) {
						this.need_question = true;
					}

					await this.triggers.forEach(trigger => {
						this.nb_triggers++;
						let response_tab = []
						response_tab.push({
							id: -1,
							name: this.getTrad("trigger.pas_prendre_en_compte_reponse")
						})
						trigger.question.question_questiondata.data.forEach((rep, index) => {
							if(rep !== "---"){
								response_tab.push({
									id: index,
									name: rep
								})
							}
						})
						this.form.questions.push({
							id: trigger.question.question_id,
							name: trigger.question.question_libelle,
							responses: response_tab
						})
						responses.push(trigger.trigger_reponse)
					})
					if(this.nb_triggers !== 1)
						this.nb_triggers--;

					this.form.responses = responses

					let triggered = await this.getTriggered(this.$route.params.triggerentity_id);
					this.form.triggeredentity_triggeredentitytype = {
						id: triggered.triggered_triggerentitytype.triggeredentitytype_id,
						name: this.getTrad("trigger.triggered_entity_type."+triggered.triggered_triggerentitytype.triggeredentitytype_label),
						type: triggered.triggered_triggerentitytype.triggeredentitytype_label
					}

					if(triggered.triggered_triggerentitytype.triggeredentitytype_label === 'acte'){
						this.form.triggered_actestype = {
							id: triggered.triggered_actestype.actestype_id,
							name: triggered.triggered_actestype.actestype_label,
						}
						this.need_period = true;
						this.need_acte_triggered = true;
					}

					let periods = await this.getPeriodsByTriggered(triggered.triggered_id);
					const type_ref = await this.getActesTypesDetails()

					periods.forEach(period => {
						this.nb_periods++;

						switch(this.$i18n.locale()) {
							case 'fr':
								this.label.push(period.triggeredperiod_label)
								break;
							case 'en':
								this.label.push(period.triggeredperiod_label_en)
								break;
							default:
								this.label.push(period.triggeredperiod_label)
						}

						this.label_fr.push(period.triggeredperiod_label)
						this.label_en.push(period.triggeredperiod_label_en)
						this.periods.push(period.triggeredperiod_begin_at)
						this.pas.push(period.triggeredperiod_recurrence)
						this.duree.push(period.triggeredperiod_duration)

						let type = null
						if(period.triggeredperiod_actestype) {
							const acte_type = type_ref.find(actetype => actetype.actestype_id == period.triggeredperiod_actestype)
							type = {
								id: acte_type.actestype_id,
								name: acte_type.actestype_label
							}
						}
						this.actestype.push(type)

						switch (period.triggeredperiod_billable) {
							case 0:
								this.billable.push({
									id: period.triggeredperiod_billable,
									name: this.getTrad("trigger.facturer_aucun")
								})
								break;
							case 1:
								this.billable.push({
									id: period.triggeredperiod_billable,
									name: this.getTrad("trigger.tout_facturer")
								})
								break;
							case 2:
								this.billable.push({
									id: period.triggeredperiod_billable,
									name: this.getTrad("trigger.facturer_un_seul")
								})
								break;
							default:
								console.log("Error => periods billable");
						}
					})
				}
				else {
					this.form = this.default_form
				}
				this.loadTrigger = false
			},
			add_question() {
				this.nb_triggers++
			},
			findTranslation(string, appendedString) {
				const combinedString = appendedString + string
				if (this.$t(combinedString) === combinedString) return string
				return this.$t(combinedString)
			},
			removeTag(option, index){
				const indexToRemove = this.form.responses[index].indexOf(option)
				return indexToRemove > -1 ? this.form.responses[index].splice(indexToRemove, 1) : null
			},
			makeFocusOn (inputname, line) {
				if(inputname.indexOf("On_") > -1)
				{
					this.$refs[inputname][0].$el.classList.add("active");
					this.$refs[inputname.replace("On", "Out")][0].$el.classList.remove("active");
					this.$refs[inputname][0].focus();
				}
				else
				{
					this.$refs[inputname.replace("Out", "On")][0].$el.classList.remove("active");
					this.$refs[inputname][0].$el.classList.add("active");
				}

				if(inputname.indexOf("debutOn_" + (line - 1)) == 0)
				{
					this.nb_periods++
				}
			},
			getPeriodLabel(value, cle) {
				if(!value && value !== 0)
				{
					value = "x"
				}

				if(cle == "debut")
				{
					if(value == 0)
					{
						return this.getTrad("trigger.periods.debut.today")
					}
					else if(value == 1)
					{
						return this.getTrad("trigger.periods.debut.demain")
					}
					else
					{
						return this.getTrad("trigger.periods.debut.x_jours", { x: value })
					}
				}

				if(cle == "pas")
				{
					if(value == 0)
					{
						return this.getTrad("trigger.periods.pas.0_jour")
					}
					
					if(value == 1)
					{
						return this.getTrad("trigger.periods.pas.1_jour")
					}
					else
					{
						return this.getTrad("trigger.periods.pas.x_jours", { x: value })
					}
				}

				if(cle == "duree")
				{
					if(value == 0)
					{
						return this.getTrad("trigger.periods.pas.0_jour")
					}
					
					return this.getTrad("trigger.periods.duree.x_jours", { x: value })
				}
			},
			deleteQuestion(i) {
				this.form.questions.splice(i, 1);
				this.form.responses.splice(i, 1);
				this.nb_triggers--

				if(this.nb_triggers == 0)
				{
					this.nb_triggers = 1
				}
			},
			async checkForm() {
				if(this.processing_form == false)
				{
					this.errors = []
					this.processing_form = true

					// if(this.form.questions.length != this.form.responses.length)
					// {
						for (let i = 0; i < this.form.questions.length; i++) {
							if((this.form.responses[i] == undefined || this.form.responses[i].length == 0) && this.need_question)
							{
								this.errors.push("responses_" + i)
							}
						}
					// }
					if(!this.form.triggeredentity_triggeredentitytype)
					{
						this.errors.push("triggeredentity_triggeredentitytype")
					}

					if(!this.form.triggered_actestype && this.need_acte_triggered)
					{
						this.errors.push("triggered_actestype")
					}

					if(!this.form.triggered_categorie && this.need_categorie)
					{
						this.errors.push("triggered_categorie")
					}

					if(this.label[0] === "" || this.label[0] === undefined && this.need_period)
					{
						this.errors.push("triggered_label")
					}
					if(this.periods[0] === "" || this.periods[0] === undefined && this.need_period)
					{
						this.errors.push("triggered_periods")
					}
					if(this.pas[0] === "" || this.pas[0] === undefined && this.need_period)
					{
						this.errors.push("triggered_pas")
					}
					if(this.duree[0] === "" || this.duree[0] === undefined && this.need_period)
					{
						this.errors.push("triggered_duree")
					}

					if(this.errors.length > 0)
					{
						this.failureToast("trigger.erreur_trigger")
						this.processing_form = false
						return
					}
					let data =  {
						triggered_actestype: this.need_acte_triggered ? this.form.triggered_actestype.id : 0,
						triggered_categorie: this.need_categorie ? this.form.triggered_categorie.categorie_id : 0,
						triggeredentity_triggeredentitytype: this.form.triggeredentity_triggeredentitytype.id,
						triggerentity_actestype: this.need_acte ? this.form.triggerentity_actestype.id : 0,
						triggerentity_triggerentitytype: this.form.triggerentity_triggerentitytype.id,
						questions: this.form.questions,
						responses: this.form.responses,
						periods: {	
								label: this.label,
								label_fr: this.label_fr,
								label_en: this.label_en,
								periods: this.periods,
								pas: this.pas,
								duree: this.duree,
								billable: this.billable,
								actestype: this.actestype
							},
						global: this.global
					}
					if(this.errors.length === 0){
						let res = null
						if(this.$route.params.triggerentity_id != undefined){
							// res = await this.saveTrigger(data, this.$route.params.triggerentity_id);
							res = await this.updateTrigger(data, this.$route.params.triggerentity_id)
						}
						else{
							// res = await this.saveTrigger(data);
							res = await this.createTrigger(data)
						}
						if(res) {
							this.successToast("toast.info_save_succes")
							this.$router.push({name: 'triggersList'})
						}
					}
					this.processing_form = false
				}
			},
			deletePeriod(i) {
				this.label.splice(i, 1);
				this.periods.splice(i, 1);
				this.pas.splice(i, 1);
				this.duree.splice(i, 1);
				this.billable.splice(i, 1);
				this.actestype.slice(i, 1);
				this.nb_periods--
				if(this.nb_periods === 0)
					this.nb_periods = 1
			},
			async getActesTypesVue() {
				let actes_type = await this.getActesTypes()
				actes_type = actes_type.filter(acte_type => acte_type.actestype_label !== "---")


				let actes_tab = {}
				for (let i = 0; i < actes_type.length; i++) {
					if(!Object.prototype.hasOwnProperty.call(actes_tab, actes_type[i].actestype_codegroupe)){
						actes_tab[actes_type[i].actestype_codegroupe] = []
					}
					actes_tab[actes_type[i].actestype_codegroupe].push({
						id: actes_type[i].actestype_id,
						name: actes_type[i].actestype_label,
					})
				}

				let res = []
				for(let groupe in actes_tab) {
					res.push({
						groupe: this.$t('actes_groupe.' + groupe),
						actes: actes_tab[groupe]
					})
				}

				return res
			}
		},
		computed: {
			
		},
		watch: {
			//Déclencheurs
			async 'form.triggerentity_actestype'(val) {
				let questions_tab = []
				let responses_tab = []

				this.form.questions = []
				this.form.responses = []

				if(val != undefined)
				{
					this.isLoadingQuestions = true

					let questions = await this.loadQuestionFromType(val.id)
					for (let i = 0; i < questions.length; i++) {
						if(questions[i].question_questiontype !== "SEL" && questions[i].question_questiontype !== "BOO") {
							continue;
						}

						if(responses_tab[i] == undefined) {
							responses_tab[i] = []
						}
						responses_tab[i].push({
							id: -1,
							name: this.getTrad("trigger.pas_prendre_en_compte_reponse")
						})
						for (let j = 0; j < questions[i].question_questiondata.data.length; j++) {
							
							if(questions[i].question_questiondata.data[j] !== "---"){
								responses_tab[i].push({
									id: j,
									name: questions[i].question_questiondata.data[j]
								})
							}
						}

						questions_tab.push({
							id: questions[i].question_id,
							name: questions[i].question_libelle,
							responses: responses_tab[i]
						})
					}
					
					this.nb_triggers = 1
				}

				this.questions = questions_tab
				this.isLoadingQuestions = false
			},
			async 'form.triggerentity_triggerentitytype' (val) {
				this.isLoadingTriggerActeType = true
				this.need_question = this.need_acte = false
				switch (val.type) {
					case 'acte':
						this.need_question = this.need_acte = true
						this.actes_type = await this.getActesTypesVue()
						break;
				}
				this.isLoadingTriggerActeType = false
			},
			async 'form.triggeredentity_triggeredentitytype' (val) {
				this.need_period = this.need_acte_triggered = this.need_categorie = false
				switch (val.type) {
					case 'acte':
						this.isLoadingTriggeredActeType = true
						this.need_period = this.need_acte_triggered = true
						this.triggered_actestype = await this.getActesTypesVue()
						this.isLoadingTriggeredActeType = false
						break;
					case 'add_categorie_perso':
						this.isLoadingHorseCategories = true
						this.need_categorie = true
						this.horse_categories = await this.getAllCategorie()
						this.isLoadingHorseCategories = false
						break;
				}
			},
			'form.responses'(val){
				if(val.length > 0)
				{
					this.nb_periods = 1
				}
			},
		},
		components: {
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
		}
	}
</script>